<template>
  <div>
<!--    <el-form-item label="Тип">-->
      <el-checkbox
        v-model="infoLocal.card_type"
        size="mini"
        true-label="top_level"
        :false-label="null"
        @change="valueChanged"
      >
        Для директоров 20+
      </el-checkbox>
<!--    </el-form-item>-->

  </div>

</template>

<script>
import {mapGetters} from "vuex";
import ClickToEdit from "@/components/ClickToEdit.vue";

export default {
  name: "cards-create-info",
  components: {},

  props: {
    info: {type: Object, required:true},
    card: {type: Object},
  },

  computed: {},
  watch: {
    'info': function(newVal){
      this.infoLocal = newVal;
    },
  },
  data() {
    return {
      infoLocal: {
        card_type: null,
      },
    }
  },
  methods: {
    valueChanged(){
      this.$emit('update:info', this.infoLocal);
    }
  }
}
</script>


<style lang="scss">

</style>